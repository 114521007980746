import "components/next/pi-ll";
import { createEventCapture, findParentTag } from "next/common";

const MOBILE_BREAKPOINT = 550;
const SearchEvent = createEventCapture();
load_googlemaps();
class InputOverlayElement extends HTMLElement {
    constructor() {
        super();
        this.id = this.tagName.toLowerCase();

        this.checkViewport = this.checkViewport.bind(this);
        this.checkViewport();

        this.init = this.init.bind(this);
        this.clear = this.clear.bind(this);
        this.open = this.open.bind(this);
        this.set = this.set.bind(this);

        this.addEventListener("focusin", (event) => {
            if (event.target.tagName == "INPUT") {
                this.open();
            }
        });

        this.init();
    }

    connectedCallback() {
        window.addEventListener("resize", this.checkViewport);
        document.body.addEventListener(`${this.id}:clear`, this.clear);
        document.body.addEventListener(`${this.id}:set`, this.set);
    }

    disconnectedCallback() {
        window.removeEventListener("resize", this.checkViewport);
        document.body.removeEventListener(`${this.id}:clear`, this.clear);
    }

    checkViewport(event) {
        this.isOverlayEnabled = window.innerWidth <= MOBILE_BREAKPOINT;
    }

    createOverlay() {
        let overlay = document.querySelector(`overlay-div#${this.id}`);
        if (overlay) {
            overlay.remove();
        }

        overlay = document.createElement("overlay-div");
        overlay.setAttribute("id", this.id);
        document.body.append(overlay);

        return overlay;
    }

    init() {
        if ("selection" in this.attributes) {
            let selection = JSON.parse(this.getAttribute("selection"));
            this.createOverlay();
            this.render({ state: "result", props: selection });
            this.removeAttribute("selection");
        } else {
            this.render({ state: "init" });
        }
    }

    open() {
        if (this.isOverlayEnabled) {
            let overlay = document.querySelector(`overlay-div#${this.id}`);
            overlay.setAttribute("open", true);
        }
    }

    clear(event) {
        this.init();
        if (
            event.detail == undefined ||
            event.detail.origin != "top-search-items"
        ) {
            this.open();
        }
        SearchEvent.capture({ type: `${this.id}__clear` });

        let form = this.closest("rm-search");
        if (form) {
            form.dispatchEvent(
                new CustomEvent("rm-search:clear", {
                    detail: { name: this.id },
                }),
            );
        }
    }

    render({ state }) {
        if (state == "result") {
            if (this.isOverlayEnabled) {
                document
                    .querySelector(`overlay-div#${this.id}`)
                    .removeAttribute("open");
            }
        }
    }

    set(event) {
        let target = event.detail.target;
        let term = target.querySelector("te-rm").innerText;
        if (!target.dataset.hasOwnProperty("fakeRender")) {
            this.render({
                state: "result",
                props: {
                    pill: term,
                    icon: target.querySelector("rm-icon").getAttribute("name"),
                    input: {
                        value: target.dataset.value,
                        name: target.dataset.pathType,
                    },
                },
            });
        }

        SearchEvent.capture({
            type: event.detail.searchEvent.type,
            data: {
                term,
                resource: target.dataset.resource,
                resource_id: parseInt(target.dataset.id),
                ...event.detail.searchEvent.data,
            },
        });
    }
}

customElements.define(
    "address-autocomplete",
    class extends InputOverlayElement {
        static observedAttributes = ["gm-loaded", "disabled"];

        constructor() {
            super();

            this.toggle = this.toggle.bind(this);
        }

        connectedCallback() {
            super.connectedCallback && super.connectedCallback();

            document.body.addEventListener(
                "address-autocomplete:toggle",
                this.toggle,
            );
        }

        disconnectedCallback() {
            super.disconnectedCallback && super.disconnectedCallback();

            document.body.removeEventListener(
                "address-autocomplete:toggle",
                this.toggle,
            );
        }

        toggle(event) {
            let callback = () =>
                this.setAttribute("disabled", event.detail.set);

            if (event.detail.set) {
                this.render({ state: "init", props: { callback } });
                SearchEvent.capture({
                    type: "address-autocomplete__clear",
                });
            } else {
                this.setAttribute("disabled", false);
            }
        }

        attributeChangedCallback(name, oldValue, newValue) {
            if (
                name == "gm-loaded" &&
                newValue == "true" &&
                this.querySelector("pi-ll") == null
            ) {
                this.render({ state: "init" });
            }

            if (name == "disabled") {
                if (newValue == "true") {
                    this.querySelector("input").setAttribute("disabled", true);
                } else if (newValue == "false") {
                    this.querySelector("input").removeAttribute("disabled");
                }
            }
        }

        render({ state, props = {} }) {
            super.render && super.render({ state, props });

            if (state == "init") {
                let tpl = document.createElement("template");
                tpl.innerHTML = `
                    <div data-react__address-autocomplete-radius-search>
                        <input type="text" />
                    </div>
                `;
                let container = this;

                if (this.isOverlayEnabled) {
                    container = this.createOverlay();
                    this.innerHTML = `
                    <input
                        type="text"
                        placeholder="${this.getAttribute("aria-label")}"
                    />`;
                } else {
                    this.innerHTML = "";
                }

                container.append(tpl.content.cloneNode(true));
                if (this.getAttribute("gm-loaded") == "true") {
                    init_addressautocomplete({
                        onClickSuggestion: () => {
                            document
                                .querySelector("address-autocomplete")
                                .dispatchEvent(
                                    new CustomEvent(
                                        "address-autocomplete:result",
                                    ),
                                );
                        },
                        onClickNoResult: () => {
                            this.querySelector("input").value = "";
                            this.querySelector("input").focus();
                        },
                        afterImport: props.callback,
                        includeLabel: () => {
                            return this.isOverlayEnabled;
                        },
                    });
                    this.attachEvents(container);
                }
            } else if (state == "result") {
                this.innerHTML = "";
                let tpl = document.createElement("template");
                tpl.innerHTML = `
                    <div>
                        <pi-ll
                            onclose="address-autocomplete:clear"
                        >
                            <rm-icon-text>
                                <rm-icon name="${props.icon}"></rm-icon>
                                <span>${props.pill}</span>
                            </rm-icon-text>
                        </pi-ll>
                        <select id="searchRadius" name="radius">
                            <option value="5km">5 km</option>
                            <option value="10km">10 km</option>
                            <option value="15km">15 km</option>
                            <option value="20km">20 km</option>
                            <option value="25km">25 km</option>
                            <option value="50km">50 km</option>
                            <option value="100km">100 km</option>
                            <option value="200km">200 km</option>
                        </select>
                        <input
                            type="hidden"
                            name="path__address"
                            value="${props.input.path}"
                        />
                        <input
                            type="hidden"
                            name="query__address"
                            value="${props.input.query}"
                        />
                    </div>
                `;
                this.append(tpl.content.cloneNode(true));
                let selectedRadius =
                    props.select_value || this.dataset.defaultRadius;
                const dd = document.getElementById("searchRadius");
                dd.selectedIndex = [...dd.options].findIndex(
                    (option) => option.value == selectedRadius,
                );
            }
        }

        attachEvents(container) {
            this.addEventListener("address-autocomplete:result", (event) => {
                event.stopImmediatePropagation();
                let result = {};
                for (let input of document
                    .querySelector(
                        "[data-react__address-autocomplete-radius-search]",
                    )
                    .querySelectorAll("input[type=hidden]")) {
                    result[input.name] = input.value;
                }

                if (Object.keys(result).length > 0) {
                    this.render({
                        state: "result",
                        props: {
                            pill: result.address,
                            icon: "location_on",
                            input: {
                                path: `/${result.lat}/${result.lng}/`,
                                query: `address=${result.address}`,
                            },
                            result,
                        },
                    });

                    SearchEvent.capture({
                        type: "address-autocomplete__selection",
                        data: {
                            lat: result.lat,
                            lng: result.lng,
                            types: result.types,
                        },
                    });
                }
            });
        }
    },
);

class VisibleListView {
    constructor() {
        this.top = 0;
        this.bottom = 0;
    }

    init(container) {
        this.container = container;
        this.top = 0;
        this.bottom = container.clientHeight;
    }

    isInView(item) {
        let [itemTop, itemBottom] = [
            item.offsetTop,
            item.offsetTop + item.offsetHeight,
        ];

        return itemTop >= this.top && itemBottom <= this.bottom;
    }

    scrollDown(offset) {
        this.top += this.container.clientHeight;
        this.bottom += this.container.clientHeight;

        this.scrollTo(this.top - offset);
    }

    scrollUp(offset = 0) {
        this.top -= this.container.clientHeight;
        this.bottom -= this.container.clientHeight;

        this.scrollTo(this.top - offset);
    }

    scrollTo(top) {
        this.container.scrollTo({
            top,
            behavior: "smooth",
        });
    }
}

customElements.define(
    "resource-suggest",
    class extends InputOverlayElement {
        constructor() {
            super();

            this.visibleListView = new VisibleListView();

            this.closeResultList = (e) => {
                if (
                    (e && e.type === "popstate") ||
                    (!this.contains(e.target) && this.querySelector(".result"))
                ) {
                    this.render({ state: "init" });
                }
            };
        }

        connectedCallback() {
            super.connectedCallback && super.connectedCallback();
            document.body.addEventListener("click", this.closeResultList);
            window.addEventListener("popstate", this.closeResultList);
        }

        disconnectedCallback() {
            super.disconnectedCallback && super.disconnectedCallback();
        }

        render({ state, props = {} }) {
            super.render && super.render({ state, props });

            if (state == "init") {
                let tpl = document.createElement("template");

                let container = this;

                if (this.isOverlayEnabled) {
                    tpl.innerHTML = `
                            <fieldset>
                                <label for="overlay-rs-input">${this.getAttribute(
                                    "aria-label",
                                )}</label>
                                <input
                                    id="overlay-rs-input"
                                    type="text"
                                    placeholder="${this.getAttribute(
                                        "aria-label",
                                    )}"
                                    aria-autocomplete="list"
                                />
                            </fieldset>
                            <div class="relative-wrapper result"></div>
                        `;
                    container = this.createOverlay();
                    this.innerHTML = `<input type="text" placeholder="${this.getAttribute(
                        "aria-label",
                    )}" />`;
                } else {
                    tpl.innerHTML = `
                        <input
                            id="overlay-rs-input"
                            type="text"
                            placeholder="${this.getAttribute("aria-label")}"
                            aria-autocomplete="list"
                        />
                        <div class="relative-wrapper result"></div>
                    `;
                    this.innerHTML = "";
                }

                container.append(tpl.content.cloneNode(true));

                let form = this.closest("rm-search");
                if (form) {
                    form.dispatchEvent(
                        new CustomEvent("rm-search:input-update", {
                            detail: {
                                name: "resource-suggest",
                                set: false,
                            },
                        }),
                    );
                }
                this.attachEvents(container);
            } else if (state == "result") {
                this.innerHTML = "";
                let tpl = document.createElement("template");
                tpl.innerHTML = `
                    <div aria-label="${this.getAttribute("aria-label")}">
                        <pi-ll
                            onclose="resource-suggest:clear"
                        >
                            <rm-icon-text>
                                <rm-icon name="${props.icon}"></rm-icon>
                                <span>${props.pill}</span>
                            </rm-icon-text>
                        </pi-ll>
                        <input
                            type="hidden"
                            name="${props.input.name}"
                            value="${props.input.value}"
                        />
                    </div>
                `;
                this.append(tpl.content.cloneNode(true));

                if (props.input.name == "url") {
                    let form = this.closest("rm-search");
                    if (form) {
                        form.dispatchEvent(
                            new CustomEvent("rm-search:input-update", {
                                detail: {
                                    name: "resource-suggest",
                                    set: true,
                                },
                            }),
                        );
                    }
                }
            }
        }

        attachEvents(container) {
            let input = container.querySelector("input");
            let results = container.querySelector(".result");

            const ARROW_UP = 38;
            const ARROW_DOWN = 40;

            const keys = {
                ENTER: 13,
                ESC: 27,
                ARROWS: [ARROW_UP, ARROW_DOWN],
            };

            const renderResult = (target) => {
                let term = target.querySelector("te-rm").innerText;
                this.render({
                    state: "result",
                    props: {
                        pill: term,
                        icon: target
                            .querySelector("rm-icon")
                            .getAttribute("name"),
                        input: {
                            value: target.dataset.value,
                            name: target.dataset.pathType,
                        },
                    },
                });

                SearchEvent.capture({
                    type: "resource-suggest__selection",
                    data: {
                        term,
                        resource: target.dataset.resource,
                        resource_id: target.dataset.id,
                        origin: target.dataset.searchEventOrigin,
                    },
                });
            };

            results.addEventListener("click", (event) => {
                let item = event.target.closest(".suggestion-item");
                if (item) {
                    if (item.classList.contains("suggestion-item--noresult")) {
                        this.render({ state: "init" });
                    } else {
                        renderResult(event.target.closest(".suggestion-item"));
                    }
                }
            });

            results.addEventListener("pointerleave", (event) => {
                let active = results.querySelector(".suggestion-item--active");
                if (active) {
                    active.classList.remove("suggestion-item--active");
                    active.classList.add("suggestion-item--inactive");
                }
            });

            input.addEventListener("keyup", (event) => {
                if (event.keyCode == keys.ENTER) {
                    if (results.querySelector(".suggestion-item--active")) {
                        renderResult(
                            results.querySelector(".suggestion-item--active"),
                        );
                    } else {
                        if (input.value.length > 0) {
                            this.render({
                                state: "result",
                                props: {
                                    pill: input.value,
                                    icon: "search",
                                    input: {
                                        value: `/by-name/${input.value}/`,
                                        name: "url",
                                    },
                                },
                            });

                            SearchEvent.capture({
                                type: "resource-suggest__selection",
                                data: {
                                    term: input.value,
                                    resource: "keyword",
                                },
                            });
                        }
                    }
                }

                if (keys.ARROWS.includes(event.keyCode)) {
                    let end = input.value.length;
                    input.setSelectionRange(end, end);

                    let active = results.querySelector(
                        ".suggestion-item--active",
                    );
                    let tobeActive;
                    if (active) {
                        if (event.keyCode == ARROW_DOWN) {
                            tobeActive = active.nextElementSibling;
                            if (tobeActive == null) {
                                tobeActive = results.querySelector(
                                    ".suggestion-item--inactive",
                                );
                            }
                        } else if (event.keyCode == ARROW_UP) {
                            tobeActive = active.previousElementSibling;
                            if (tobeActive == null) {
                                tobeActive = results.querySelector(
                                    ".suggestion-item--inactive:last-child",
                                );
                            }
                        }

                        active.classList.remove("suggestion-item--active");
                        active.classList.add("suggestion-item--inactive");
                    } else {
                        tobeActive = results.querySelector(
                            ".suggestion-item--inactive",
                        );
                    }

                    if (tobeActive) {
                        if (!this.visibleListView.isInView(tobeActive)) {
                            if (event.keyCode == ARROW_DOWN) {
                                this.visibleListView.scrollDown(
                                    tobeActive.offsetHeight,
                                );
                            } else if (event.keyCode == ARROW_UP) {
                                this.visibleListView.scrollUp();
                            }
                        }

                        tobeActive.classList.remove(
                            "suggestion-item--inactive",
                        );
                        tobeActive.classList.add("suggestion-item--active");
                    }
                }

                if (event.keyCode == keys.ESC) {
                    this.render({ state: "init" });
                }
            });

            input.addEventListener("input", (event) => {
                if (input.value.length == 0) {
                    results.innerHTML = "";
                }
            });

            input.addEventListener(
                "input",
                debounce((event) => {
                    results.innerHTML = "";

                    if (input.value.length == 0) {
                        return;
                    }

                    results.innerHTML = `
                        <rm-icon-text>
                            <rm-loader loading></rm-loader>
                            <span>Loading...</span>
                        </rm-icon-text>
                        `;

                    fetch(`/suggest-next/${input.value.toLowerCase()}/`, {
                        method: "GET",
                        headers: {
                            Accept: "text/html",
                        },
                    })
                        .then(function (response) {
                            return response.text();
                        })
                        .then((html) => {
                            results.innerHTML = html;

                            SearchEvent.capture({
                                type: "resource-suggest__suggestions",
                                data: {
                                    term: input.value,
                                    professions: results.querySelectorAll(
                                        "[data-resource=profession]",
                                    ).length,
                                    services: results.querySelectorAll(
                                        "[data-resource=service]",
                                    ).length,
                                    providers: results.querySelectorAll(
                                        "[data-resource=provider]",
                                    ).length,
                                },
                            });

                            this.attachEventsOnResult(results);
                        });
                }, 350),
            );

            input.addEventListener("focus", (event) => {
                if (document.querySelector("#top-suggestions")) {
                    results.append(
                        document
                            .querySelector("#top-suggestions")
                            .content.cloneNode(true),
                    );
                    this.attachEventsOnResult(results);
                }
            });

            input.addEventListener("focusout", (event) => {
                if (document.querySelector("#top-suggestions")) {
                    if (event.relatedTarget) {
                        this.render({ state: "init" });
                    }
                }
            });
        }

        attachEventsOnResult(results) {
            let suggestionContainer = results.querySelector(
                ".suggestion-list-container",
            );
            if (suggestionContainer) {
                this.visibleListView.init(
                    results.querySelector(".suggestion-list-container"),
                );

                results.querySelectorAll(".suggestion-item").forEach((item) => {
                    item.addEventListener("pointerenter", (event) => {
                        if (
                            item.classList.contains("suggestion-item--noresult")
                        ) {
                            return;
                        }

                        let active = results.querySelector(
                            ".suggestion-item--active",
                        );
                        if (active) {
                            active.classList.remove("suggestion-item--active");
                            active.classList.add("suggestion-item--inactive");
                        }
                        item.classList.add("suggestion-item--active");
                        item.classList.remove("suggestion-item--inactive");
                    });
                });
            }
        }
    },
);

customElements.define(
    "overlay-div",
    class extends HTMLElement {
        static observedAttributes = ["open"];
        constructor() {
            super();
        }

        connectedCallback() {
            let closeButton = document.createElement("button");
            closeButton.setAttribute("ord", "tertiary");
            closeButton.setAttribute("size", "small");
            closeButton.innerText = "Close";
            closeButton.addEventListener("click", (event) => {
                this.removeAttribute("open");
            });

            this.insertAdjacentElement("afterbegin", closeButton);
        }

        attributeChangedCallback(name, oldValue, newValue) {
            if (name == "open") {
                if (!document.body.classList.contains("noscroll")) {
                    document.body.classList.toggle("noscroll");
                }
                if (newValue == "true") {
                    this.querySelector("input").focus();
                }
            }
        }
    },
);

customElements.define(
    "rm-search-filters",
    class extends HTMLElement {
        constructor() {
            super();
        }

        connectedCallback() {
            this.querySelector("form").addEventListener("submit", (event) => {
                event.preventDefault();
                if ("resetFilters" in event.submitter.dataset) {
                    this.resetFilters();
                    document.querySelector("[data-reset-filters]").style.display = "none";
                    this.submit();
                }
            });

            this.querySelector("form").addEventListener("click", (event) => {
                if (
                    event.target.tagName == "INPUT" &&
                    ["checkbox", "radio"].includes(event.target.type)
                ) {
                    let details = findParentTag("details")(event.target);
                    if (details && details.classList.contains("rm-search__filter")) {
                        details
                            .querySelector("rm-search-filter-counter")
                            .setAttribute(
                                "counter",
                                details.querySelectorAll("input:checked").length,
                            );
                    }

                    document.querySelector("[data-reset-filters]").style.display =
                        document.querySelectorAll("rm-search-filter-counter:not([counter='0'])")
                            .length
                            ? "block"
                            : "none";

                    this.submit();
                }
            });

            window.addEventListener("popstate", (event) => {
                window.location.reload();
            });
        }

        submit() {
            let formData = new FormData(this.querySelector("form"));
            let searchParams = new URLSearchParams(window.location.search);
            if (searchParams.has("offset")) {
                searchParams.delete("offset")
            }

            Array.from(this.querySelectorAll("[data-filter]"))
                .map((f) => f.dataset.filter)
                .forEach((filter) => {
                    if (searchParams.has(filter)) {
                        searchParams.delete(filter);
                    }
                    if (formData.getAll(filter).length > 0) {
                        searchParams.set(filter, formData.getAll(filter).join(","));
                    }

                });

            let nextURL = `${window.location.pathname}?${searchParams.toString()}`;

            document.querySelector(".main-content").innerHTML = `
                <div class="loader">
                    <p>Fetching new results</p>
                    <rm-loader loading large></rm-loader>
                </div>
            `;

            fetch(nextURL)
                .then((response) => {
                    return response.text();
                })
                .then((html) => {
                    window.history.pushState(null, "", nextURL);
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(html, "text/html");
                    for (const sel of [".main-content", "#copy", ".pagination-section__top"]) {
                        try {
                            const next = doc.querySelector(sel);
                            const prev = document.querySelector(sel);
                            prev.innerHTML = next.innerHTML;
                        } catch (error) {
                            console.log(error);
                            console.error(sel);
                        }
                    }
                });
        }

        resetFilters() {
            this.querySelectorAll("[data-filter] :is(input[type=checkbox], input[type=radio])").forEach((checkbox) => {
                checkbox.checked = false;
            });
            this.querySelectorAll("rm-search-filter-counter").forEach((counter) => {
                counter.setAttribute("counter", 0);
            });
        }
    },
);

customElements.define(
    "rm-search",
    class extends HTMLElement {
        constructor() {
            super();

            this.querySelector("form").addEventListener("submit", (event) => {
                event.preventDefault();
                this.submit();
            });

            this.addEventListener("rm-search:input-update", function (event) {
                document.body.dispatchEvent(
                    new CustomEvent(event.detail.nextEventName, {
                        detail: event.detail,
                    }),
                );

                if (event.detail.name == "resource-suggest") {
                    document.body.dispatchEvent(
                        new CustomEvent("address-autocomplete:toggle", {
                            detail: event.detail,
                        }),
                    );
                }
            });

            this.addEventListener("rm-search:clear", function (event) {
                if (event.detail.name == "resource-suggest") {
                    document.body.dispatchEvent(
                        new CustomEvent("top-search-items:clear"),
                    );
                }
            });
        }

        submit() {
            let formData = new FormData(this.querySelector("form"));
            let nextURL;
            let openInNewTab = false;
            if (formData.get("url")) {
                nextURL = formData.get("url");
            } else {
                let path = this.querySelector("form").getAttribute("action");
                if (formData.get("path__address")) {
                    path += formData.get("path__address");
                    path += `${formData.get("radius")}/`;
                }

                let querystring = "";
                if (formData.get("query")) {
                    querystring += `${formData.get("query")}`;
                }

                if (formData.get("query__address")) {
                    querystring += `&${formData.get("query__address")}`;
                }

                if (querystring.length) {
                    path += `?${querystring}`;
                }

                nextURL = path;

                if (formData.get("base")) {
                    let base = new URL(formData.get("base"));
                    let _next = new URL(`${base.origin}${path}`);
                    for (let [k, v] of base.searchParams.entries()) {
                        _next.searchParams.append(k, v);
                    }
                    nextURL = _next.toString();
                    openInNewTab = true;
                }
            }

            let btn = this.querySelector("button[type=submit]:not(:disabled)");
            if (openInNewTab) {
                if (btn.querySelector("rm-loader")) {
                    btn.querySelector("rm-loader").remove();
                }
                window.open(nextURL, "_blank").focus();
            } else {
                if (!btn.querySelector("rm-loader")) {
                    btn.insertAdjacentHTML(
                        "afterbegin",
                        "<rm-loader loading></rm-loader>",
                    );
                }

                window.location = nextURL;
            }
        }
    },
);

customElements.define(
    "rm-search-filter-counter",
    class extends HTMLElement {
        static observedAttributes = ["counter"];

        constructor() {
            super();
        }

        attributeChangedCallback(name, oldValue, newValue) {
            if (name == "counter") {
                let counter = parseInt(newValue);
                if (counter == 0) {
                    this.innerHTML = "";
                } else {
                    this.innerHTML = `${counter} selected`;
                }
            }
        }
    },
);

customElements.define(
    "rm-input-filter",
    class extends HTMLElement {
        constructor() {
            super();
            let wrapper = this.querySelector(".rm-input-filter__wrapper");

            wrapper.querySelector("input").addEventListener("input", (event) => {
                // :not(:has(input:checked))
                this.querySelectorAll("[data-label]").forEach((el) => el.setAttribute("hidden", true))

                if (event.target.value.length > 0) {
                    const labels = this.querySelectorAll(`[data-label*=${event.target.value} i]`);
                    if (labels) {
                        labels.forEach((el) => {
                            el.removeAttribute("hidden");
                        })
                    }
                } else {
                    this.querySelectorAll("[data-label]").forEach((el) => el.removeAttribute("hidden"));
                }
            });

            wrapper.querySelector("button").addEventListener("click", (event) => {
                wrapper.querySelector("input").value = "";
                wrapper.querySelector("input").dispatchEvent(
                    new CustomEvent("input", {
                        target: wrapper.querySelector("input"),
                    }),
                );
            });
        }
    },
);

function init_addressautocomplete(callbacks) {
    import("../../next/addressautocompleteradiussearch.js")
        .then(function (mod) {
            mod.init({
                el: document.querySelector(
                    "[data-react__address-autocomplete-radius-search]",
                ),
                onClickSuggestion: callbacks.onClickSuggestion,
                onClickNoResult: callbacks.onClickNoResult,
                includeLabel: callbacks.includeLabel,
                captureSuggestions: (data) => {
                    SearchEvent.capture({
                        type: "address-autocomplete__suggestions",
                        data,
                    });
                },
            });
        })
        .then(() => {
            if (typeof callbacks.afterImport == "function") {
                callbacks.afterImport();
            }
        });
}

function load_googlemaps() {
    if (
        document.querySelector("address-autocomplete") != null &&
        document.querySelector('script[src*="maps.googleapis"]') == null
    ) {
        window.setTimeout(function () {
            let script = document.createElement("script");
            script.type = "text/javascript";
            let key = document.querySelector("[data-google-maps-api-key]")
                .dataset.googleMapsApiKey;
            script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&language=en&callback=gmcb&loading=async`;
            window.gmcb = function () {
                document
                    .querySelector("address-autocomplete")
                    .setAttribute("gm-loaded", "true");
            };
            document.body.append(script);
        }, 0);
    }
};


function debounce(callback, wait) {
    let timeoutId = null;

    return (...args) => {
        window.clearTimeout(timeoutId);

        timeoutId = window.setTimeout(() => {
            callback.apply(null, args);
        }, wait);
    };
}
